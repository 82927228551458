import Button from "@mui/material/Button";
import { useUserContext } from "@/context/userContext";

export const ConfirmLoad = (props: { load: () => void }) => {
  const { setNoAcc, setMockAPI } = useUserContext();

  return (
    <div className="confirmLoad_div">
      <span>
        System detects that you have saved credentials. Do you want to load it?
      </span>
      <span>
        <Button
          onClick={() => {
            setNoAcc(true);
            setMockAPI(true);
          }}
          size="small"
          variant="contained"
          color="error"
        >
          No
        </Button>
        <Button
          sx={{ marginLeft: "5px" }}
          onClick={() => {
            props.load();
          }}
          size="small"
          variant="contained"
          color="success"
        >
          Yes
        </Button>
      </span>
    </div>
  );
};
