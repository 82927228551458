import { createContext, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import { toast } from "react-toastify";
import { getAccountBalance_binance } from "../utils/helper";
import { loadPublicCCXT } from "../utils/loginCCXT";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { tooltipClasses } from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material/Tooltip";
import axios from "axios";
import { useSettingsContext } from "./settingsContext";
import { useUserContext } from "./userContext";
import {
  ChartData,
  CryptoContextProps,
  CryptoProviderProps,
  FetchTableDataProps,
  Instrument,
  SelectedMarket,
} from "@/types";

const CryptoContext = createContext<CryptoContextProps>(
  {} as CryptoContextProps
);

const fetchTableData = async ({
  exchange,
  path,
  columns,
}: FetchTableDataProps) => {
  if (path !== "trading") {
    return null;
  }
  const url = `/api/routers/fetchtable`;
  const params = {
    table: "Universe",
    filters: JSON.stringify({ exchange }),
    columns: JSON.stringify(columns),
  };
  const response = await axios.get(url, { params });

  return response.data;
};

export const CryptoProvider = ({ children }: CryptoProviderProps) => {
  const { fields, apiTypeB, apiTypeD } = useSettingsContext();
  const { path, loggedinUser, saveSettingsToAuth } = useUserContext();
  const [starredData, setStarredData] = useState<Instrument[]>([]);
  const [refreshState, setRefreshState] = useState(false);
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
  const [selectedMarket, setSelectedMarket] = useState<SelectedMarket>({
    market: "binance",
    coin: "ETHBTC",
    insType: "spot",
    allInfo: {},
  });
  const [tab, setTab] = useState("binance");
  const [coin, setCoin] = useState("BTSUSDT");
  const [orderTab, setOrderTab] = useState("allExchangeBinance");

  const {
    data: binance,
    error: errorBinance,
    isLoading: loadingBinance,
    mutate: mutateBinance,
  } = useSWR({ exchange: "binance", path, columns: [] }, fetchTableData);

  const {
    data: deribit,
    error: errorDeribit,
    isLoading: loadingDeribit,
    mutate: mutateDeribit,
  } = useSWR(
    {
      exchange: "deribit",
      path,
      columns: [
        "id",
        "symbol",
        "type",
        "exchange",
        "base",
        "counter",
        "universeData",
      ],
    },
    fetchTableData
  );

  const [balance, setBalance] = useState<any>();

  const [binanceInstruments, setBinanceInstruments] = useState<
    Record<string, Instrument[]>
  >({
    spot: [],
    margin: [],
    future: [],
    delivery: [],
    option: [],
  });

  const [deribitInstruments, setDeribitInstruments] = useState<
    Record<string, Instrument[]>
  >({
    future: [],
    option: [],
    spot: [],
  });

  const [bncCurrentInstrument, setBncCurrentInstruments] = useState<
    Record<string, Instrument[]>
  >({
    spot: [],
    margin: [],
    future: [],
    delivery: [],
    option: [],
  });
  const [drbCurrentInstrument, setDrbCurrentInstruments] = useState<
    Record<string, Instrument[]>
  >({
    future: [],
    option: [],
    spot: [],
  });

  const [marketTable, setMarketTable] = useState("spot");

  useEffect(() => {
    if (path === "trading") {
      loadPublicCCXT(apiTypeB, apiTypeD);
    }
  }, [fields]);

  useEffect(() => {
    if (path === "trading") {
      loadChart("ETHBTC", "initial");
    }
  }, [path]);

  useEffect(() => {
    if (path === "trading") {
      fetchAllInstruments();
      console.log("FETCHING INSTRUMENTS");
    }
  }, []);

  useEffect(() => {
    if (tab === "deribit") {
      setMarketTable("spot");
    } else {
      setMarketTable("spot");
    }
  }, [tab]);

  useEffect(() => {
    if (!errorBinance && !errorDeribit) {
      renderInstruments(binance || [], deribit || [], "initial");
    } else if (!errorBinance) {
      toast.error("Error fetching deribit instruments");
      renderInstruments(binance || [], [], "initial");
    } else if (!errorDeribit) {
      toast.error("Error fetching binance instruments");
      renderInstruments([], deribit || [], "initial");
    } else {
      toast.error("Error fetching instruments.");
    }
  }, [starredData, binance, deribit, errorBinance, errorDeribit]);

  const fetchAllInstruments = async (type?: string) => {
    mutateBinance();
    mutateDeribit();
    let timer = 1;
    const seconds = Number(process.env.NEXT_PUBLIC_REFRESH_INSTRUMENTS) * 60;

    if (type === "refresh") {
      const interval = setInterval(() => {
        if (timer === 10) {
          if (errorBinance) {
            setRefreshState(false);
            clearInterval(interval);
          }
        }
        if (timer === seconds) {
          setRefreshState(false);
          clearInterval(interval);
        }

        timer++;
      }, 1000);
    }
  };

  const resetInstruments = () => {
    setBncCurrentInstruments({
      spot: [],
      margin: [],
      future: [],
      delivery: [],
      option: [],
    });

    setDrbCurrentInstruments({
      future: [],
      option: [],
      spot: [],
    });
  };

  const renderInstruments = async (
    b: Instrument[] = [],
    d: Instrument[] = [],
    type?: string
  ) => {
    const binanceSpotInstruments: any[] = [],
      binanceMarginInstruments: Instrument[] = [],
      binanceFutureInstruments: Instrument[] = [],
      binanceDeliveryInstruments: Instrument[] = [],
      binanceOptionInstruments: Instrument[] = [];

    const deribitFutureInstruments: Instrument[] = [],
      deribitOptionInstruments: Instrument[] = [],
      deribitSpotInstruments: Instrument[] = [];

    for (let item of b) {
      if (starredData.length) {
        const filterStarredSymbol = starredData.filter(
          (str) => +str.id === +item.id
        );

        if (filterStarredSymbol.length) {
          item.star = true;
        } else {
          item.star = false;
        }
      } else {
        item.star = false;
      }

      item = {
        ...item,
        ...item.universeData,
      };

      if (item.type === "spot") {
        binanceSpotInstruments.push(item);
      } else if (item.type === "future") {
        binanceFutureInstruments.push(item);
      } else if (item.type === "margin") {
        binanceMarginInstruments.push(item);
      } else if (item.type === "delivery") {
        binanceDeliveryInstruments.push(item);
      } else {
        binanceOptionInstruments.push(item);
      }
    }

    for (const item of d) {
      if (starredData.length) {
        const filterStarredSymbol = starredData.filter(
          (str) => +str.id === +item.id
        );

        if (filterStarredSymbol.length) {
          item.star = true;
        } else {
          item.star = false;
        }
      } else {
        item.star = false;
      }

      const UD = item.universeData;

      item.ask = UD?.ask;
      item.bid = UD?.bid;
      item.askVolume = UD?.askVolume;
      item.bidVolume = UD?.bidVolume;
      item.base = UD?.base;
      item.change = UD?.change;
      item.contractSize = UD?.contractSize;
      item.counter = UD?.counter;
      item.expiry = UD?.expiry;
      item.price = UD?.price;
      item.tickSize = UD?.tickSize;
      item.volume = UD?.volume;

      item.type === "future"
        ? deribitFutureInstruments.push(item)
        : item.type === "spot"
        ? deribitSpotInstruments.push(item)
        : deribitOptionInstruments.push(item);
    }

    setBncCurrentInstruments({
      spot: binanceSpotInstruments,
      margin: binanceMarginInstruments,
      future: binanceFutureInstruments,
      delivery: binanceDeliveryInstruments,
      option: binanceOptionInstruments,
    });

    setDrbCurrentInstruments({
      future: deribitFutureInstruments,
      option: deribitOptionInstruments,
      spot: deribitSpotInstruments,
    });

    if (type === "initial") {
      setBinanceInstruments({
        spot: binanceSpotInstruments,
        margin: binanceMarginInstruments,
        future: binanceFutureInstruments,
        delivery: binanceDeliveryInstruments,
        option: binanceOptionInstruments,
      });

      setDeribitInstruments({
        future: deribitFutureInstruments,
        option: deribitOptionInstruments,
        spot: deribitSpotInstruments,
      });
    }
  };

  const handleLoadFavorite = async () => {
    const metadata = loggedinUser?.metadata?.user_metadata;

    setStarredData(metadata?.starredData ? metadata.starredData : []);
  };

  const starCrypto = async (star: Instrument) => {
    const starredData =
      loggedinUser?.metadata?.user_metadata?.starredData || [];

    if (starredData.length) {
      const filter = starredData.filter(
        (item: { id: number }) => +item.id === +star.id
      );

      if (filter.length) {
        // REMOVE THE ITEM FROM STARRED DATA
        const newStars = starredData.filter(
          (item: { id: number }) => +item.id !== +star.id
        );

        saveSettingsToAuth(newStars, "starredData");
        setStarredData(newStars);
      } else {
        if (
          starredData.length >= Number(process.env.NEXT_PUBLIC_MAX_FAVORITES)
        ) {
          toast.error(
            "You can't have more than ten instruments in your favorites"
          );
          return;
        }

        saveSettingsToAuth([...starredData, star], "starredData");

        setStarredData([...starredData, star]);
      }
    } else {
      const filter = starredData.filter(
        (item: { id: number }) => +item.id === +star.id
      );

      if (filter.length) {
        const newStars = starredData.filter(
          (item: { id: number }) => +item.id !== +star.id
        );
        saveSettingsToAuth(newStars, "starredData");
        setStarredData(newStars);
      } else {
        saveSettingsToAuth([star], "starredData");
        setStarredData([star]);
      }
    }
  };

  const changeTab = (e: string) => {
    setTab(e);
  };

  const BootstrapTooltip = styled(
    ({ className, ...props }: TooltipProps & { className?: string }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    )
  )(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const changeOrderTab = (e: string) => {
    setOrderTab(e);
  };

  const searchCrypto = (str: string) => {
    const type = marketTable;
    const market = tab;
    const crypto: any =
      market === "binance"
        ? binanceInstruments[type]
        : deribitInstruments[type];

    if (!crypto?.error) {
      if (str) {
        const updatedCrypto = crypto?.filter((item: { symbol: string }) => {
          const d = str?.toLowerCase();
          const searchName = item.symbol.toLowerCase().includes(d);
          const searchSymbol = item.symbol
            .replace("/", "")
            .toLowerCase()
            .includes(d);

          if (searchName || searchSymbol) {
            return item;
          }
          return null;
        });

        tab === "binance"
          ? setBncCurrentInstruments({
              ...bncCurrentInstrument,
              [type]: updatedCrypto,
            })
          : setDrbCurrentInstruments({
              ...drbCurrentInstrument,
              [type]: updatedCrypto,
            });
      } else {
        tab === "binance"
          ? setBncCurrentInstruments({
              ...bncCurrentInstrument,
              [type]: crypto,
            })
          : setDrbCurrentInstruments({
              ...drbCurrentInstrument,
              [type]: crypto,
            });
      }
    }
  };

  const loadChart = (
    coin: string,
    type: string,
    insType?: string,
    allInfo?: any
  ) => {
    if (type === "change") {
      setSelectedMarket({
        market: allInfo.exchange,
        coin: allInfo.symbol,
        insType: allInfo.type,
        allInfo,
      });
      setChartData({
        title: allInfo.symbol.toUpperCase(),
        symbol: allInfo.symbol.replace("/", "").toUpperCase(),
      });
      setCoin(coin);
      setOrderTab(
        tab === "binance" ? "allExchangeBinance" : "allExchangeDeribit"
      );
    }

    if (insType) {
      const allOrders_binance = localStorage.getItem("ordersBinance")
        ? JSON.parse(localStorage.getItem("ordersBinance") ?? "")
        : [];
      allOrders_binance.push({ coin, insType });
    }

    if (type !== "change") {
      setSelectedMarket({
        market: "binance",
        coin: "ETHBTC",
        insType: "spot",
        allInfo: { base: "ETH", counter: "BTC" },
      });

      setChartData({
        title: "ETHBTC".toUpperCase(),
        symbol: "ETHBTC".replace("/", "").toUpperCase(),
      });
    }

    if (tab === "binance" && type !== "initial") {
      checkBalance(insType ? insType : "spot");
    }
  };

  const checkBalance = async (e: string) => {
    const orders = await getAccountBalance_binance(e);

    if (!orders) {
      return;
    }
    if (orders.error) {
      toast.error(orders.error);
      setBalance(undefined);
      return;
    }

    setBalance(orders);
  };

  const changeMarketTable = (e: string) => {
    setMarketTable(e);
  };

  const mkFormatter = (num: number) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return `${num}`;
    }
  };

  const countTickerSize = (num: number, ticker: string) => {
    const arr = ticker ? ticker?.toString().split(".") : [];
    const count = arr[1] ? arr[1]?.length : 5;

    return num.toFixed(+count);
  };

  const countDecimals = (num: number, type?: string) => {
    const arr = num.toString().split(".");

    if (arr[0].length === 5) {
      return parseFloat(`${num}`).toFixed(1);
    }

    if (arr[0].length === 4) {
      return parseFloat(`${num}`).toFixed(2);
    }

    if (arr[0].length === 3) {
      return parseFloat(`${num}`).toFixed(2);
    }

    if (arr[0].length === 2) {
      return parseFloat(`${num}`).toFixed(3);
    }

    if (arr[0].length === 1) {
      return `${parseFloat(`${num}`).toFixed(type === "bidoffer" ? 5 : 4)}`;
    }

    if (arr[0].length > 5) {
      const str = arr[0].toString().substring(0, 5);
      const finalStr = `${str}...`;

      if (!type || type === "bidoffer") {
        return finalStr;
      } else {
        return `${parseFloat(`${num}`).toFixed(1)}`;
      }
    }

    return `${num}`;
  };

  // WILL BE USED IN THE FUTURE
  const handleCancelOpenOrder = async () =>
    // e: any
    {
      // let cancelOrder = await cancelOpenOrder(
      //   selectedMarket.market,
      //   e.id ? e.id : e.order_id ? e.order_id : e.orderId,
      //   e.symbol ? e.symbol : e.instrument_name,
      //   orderTab
      // );
      // if (cancelOrder.error) {
      //   toast.error(cancelOrder.error);
      // } else {
      //   toast.success("Successfully cancelled order.");
      //   fetchOpenOrders(selectedMarket.coin, selectedMarket.market);
      //   fetchAllOpenOrders(selectedMarket.coin, selectedMarket.market);
      //   fetchAllExchangeOrders();
      //   fetchUSDCOpenOrders();
      //   fetchSOLOpenOrders();
      //   fetchETHOpenOrders();
      //   fetchBTCOpenOrders();
      //   fetchFutureOpenOrders();
      //   fetchSpotOpenOrders();
      //   fetchOptionOpenOrders();
      //   fetchMarginOpenOrders();
      // }
    };

  const cancelAllOpenOrder = async () =>
    // ids: number[], tab: string
    {
      // let cancelOrder = await cancelAllOpenOrders(ids, selectedMarket.market);
      // if (cancelOrder.error) {
      //   toast.error(cancelOrder.error);
      // } else {
      //   toast.success("Successfully cancelled all orders.");
      //   fetchOpenOrders(selectedMarket.coin, selectedMarket.market);
      //   fetchAllOpenOrders(selectedMarket.coin, selectedMarket.market);
      //   fetchAllExchangeOrders();
      //   fetchUSDCOpenOrders();
      //   fetchSOLOpenOrders();
      //   fetchETHOpenOrders();
      //   fetchBTCOpenOrders();
      //   fetchFutureOpenOrders();
      //   fetchSpotOpenOrders();
      //   fetchOptionOpenOrders();
      //   fetchMarginOpenOrders();
      // }
    };

  return (
    <CryptoContext.Provider
      value={{
        bncCurrentInstrument,
        drbCurrentInstrument,
        chartData,
        tab,
        orderTab,
        selectedMarket,
        coin,
        balance,
        marketTable,
        BootstrapTooltip,
        starredData,
        refreshState,
        loadingBinance,
        loadingDeribit,
        setRefreshState,
        fetchAllInstruments,
        resetInstruments,
        cancelAllOpenOrder,
        handleCancelOpenOrder,
        handleLoadFavorite,
        countDecimals,
        countTickerSize,
        changeMarketTable,
        checkBalance,
        starCrypto,
        searchCrypto,
        loadChart,
        changeTab,
        changeOrderTab,
        mkFormatter,
      }}
    >
      {children}
    </CryptoContext.Provider>
  );
};

export const useCryptoContext = () => {
  const context = useContext(CryptoContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
