import { RingLoader } from "react-spinners";

export const LoadingScreen = () => {
  return (
    <div className="loadingscreen-div ">
      <span className="loadingScreen">
        <span>Authenticating .....</span>
        <RingLoader
          color="#ddd"
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </span>
    </div>
  );
};
